import { useHistory } from 'react-router-dom';
import { AuthContext } from '../globalStates';
import useUsersApi from '../hooks/useUsersApi';

import { useTranslation } from 'react-i18next';
import useLoader from '../components/loader/useLoader';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { PasswordManager } from '../helpers/password-manager';
import CustomModal from '../components/modals/customModal';
import Footer from '../components/footer/footer';

import { IoMdEye, IoMdEyeOff } from 'react-icons/io';

interface ResetPasswordProps {
	userId: string,
	activation: boolean
}
export function ResetPassword(props: ResetPasswordProps) {
	const [authState, setAuthState] = useContext(AuthContext);
	const [loader, showLoader, hideLoader] = useLoader();
	const { t } = useTranslation();
	const [, , , logoutUser, , , updateUserPassword] = useUsersApi();
	const [newPassword, setNewPassword] = useState<string>('');
	const [newPasswordLengthOk, setNewPasswordLenghtOk] = useState<boolean>(true);
	const [repeatPassword, setRepeatPassword] = useState<string>('');
	const [repeatPasswordLengthOk, setRepeatPasswordLenghtOk] = useState<boolean>(true);
	const [samePasswordOk, setSamePasswordOk] = useState(true);
	const [validated, setValidated] = useState(false);
	const dispatch = useDispatch();
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [user, setUser] = useState<any>({});
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showNewConfPassword, setShowNewConfPassword] = useState(false);
	const handleSubmit = async (e: any) => {
		const form = e.currentTarget;

		if (!validateAll(form)) {
			e.preventDefault();
			e.stopPropagation();
		} else {
			e.preventDefault();
			e.stopPropagation();

			showLoader();
			const passwordHashed = PasswordManager(newPassword).hashPassword();
			const response: any = await dispatch(updateUserPassword({ "id": props.userId, "newPassword": passwordHashed, status: "ACTIVE" }));

			hideLoader();
			if (response['payload']) {
				setUser(response['payload']);
				setShowSuccessModal(true);
			} else {
				setShowErrorModal(true);
			}
		}
		setValidated(true);
	};
	const handleOnCreateNewPassword = (event: any) => {
		setNewPassword(event.target.value);
		setNewPasswordLenghtOk(PasswordManager(event.target.value).passwordLengthOk());
		setSamePasswordOk(PasswordManager(event.target.value, repeatPassword).samePasswordOk());
	};
	const handleOnCreateRepeatPassword = (event: any) => {
		setRepeatPassword(event.target.value);
		setRepeatPasswordLenghtOk(PasswordManager(event.target.value).passwordLengthOk());
		setSamePasswordOk(PasswordManager(newPassword, event.target.value).samePasswordOk());
	};
	const handleOnLogout = async () => {
		const result: any = await dispatch(logoutUser());
		if (result) {
			await setAuthState(null);
		}
	};
	const handleCloseSuccessModal = async (e: any) => {
		if (e) {
			await handleOnLogout();
			history.push('/login');
		}
		setShowSuccessModal(false);
	};
	const handleCloseErrorModal = (e: any) => {
		setShowErrorModal(false);
	};
	const history = useHistory();
	const goToLanding = () => {
		history.push('/');
	}
	const validateAll = (form: any) => {
		return (
			form.checkValidity() &&
			newPasswordLengthOk &&
			repeatPasswordLengthOk &&
			samePasswordOk
		);
	};

	return (
		<>
			{loader}
			{showSuccessModal && user && (
				<CustomModal
					isShowing={showSuccessModal}
					parentCallback={handleCloseSuccessModal}
					message={props.activation ? t('success.password-create', { email: user['email'] }) : t('success.password-update', { email: user['email'] })}
					buttonOK={t('Continue')}
					type="SUCCESS"
				/>
			)}
			{showErrorModal && (
				<CustomModal
					isShowing={showErrorModal}
					parentCallback={handleCloseErrorModal}
					message={t('error.unexpected')}
					title={t('error.modal-title-oops')}
					buttonOK={t('accept')}
					type="ERROR"
				/>
			)}

			<div className="row w-100 login-container">
				<div className="col-12 col-lg-6 image-container d-none" >
					<div className="login-image"></div>
				</div>
				<div className="col-12 p-0 m-0 d-flex justify-content-center">
					<div className="d-flex flex-column justify-content-center align-items-center">
						<div className="d-flex row mt-3 flex-column logo-container mb-4 justify-content-center align-items-center">
							<div className="logo white cursor-pointer" onClick={goToLanding}></div>
							<div className='inscription-secondary-title'>
								{!props.activation && `${t('user.reset-password')}`}
								{props.activation && `${t('user.create-password')}`}
							</div>
						</div>
						<div className='login-box'>
							<Form
								noValidate
								validated={validated}

								onSubmit={handleSubmit}
							>


								{/* newPassword */}
								<Form.Group className="form-content pl-0" controlId="formNewPassword">
									<Form.Label className="input-label mt-0">{`${t(
										'user.new-password'
									)}`}</Form.Label>
									<Form.Control
										type={showNewPassword ? "text" : "password"}
										name="newPassword"
										placeholder={t('user.new-password')}
										onChange={handleOnCreateNewPassword}
										required
										minLength={8}
										isInvalid={!newPasswordLengthOk || !samePasswordOk}
									/>
									<span className={`eye-icon-password${(validated || (!repeatPasswordLengthOk || !samePasswordOk)) ? '-invalid' : ''} `} onClick={() => setShowNewPassword(!showNewPassword)}>
										{showNewPassword ? <IoMdEyeOff /> : <IoMdEye />}
									</span>
									{newPassword && !newPasswordLengthOk &&
										<Form.Control.Feedback type="invalid">

											{t('error.password-length')}

										</Form.Control.Feedback>
									}
									{!newPassword &&
										<Form.Control.Feedback type="invalid">

											{t('error.password-required')}

										</Form.Control.Feedback>
									}

									{/* </Form.Group>

							
							<Form.Group className="col-md-6 pl-0" controlId="formRepeatPassword"> */}
									<Form.Label className="input-label">{`${t(
										'user.confirm-password'
									)}`}</Form.Label>
									<Form.Control
										type={showNewConfPassword ? "text" : "password"}
										name="repeatPassword"
										placeholder={t('user.confirm-password')}
										onChange={handleOnCreateRepeatPassword}
										required
										minLength={8}
										isInvalid={!repeatPasswordLengthOk || !samePasswordOk}
									/>
									<span className={`eye-icon-password${(validated || (!repeatPasswordLengthOk || !samePasswordOk)) ? '-invalid' : ''} `} onClick={() => setShowNewConfPassword(!showNewConfPassword)}>
										{showNewConfPassword ? <IoMdEyeOff /> : <IoMdEye />}
									</span>
									
									{repeatPassword && !repeatPasswordLengthOk &&
										<Form.Control.Feedback type="invalid">

											{t('error.password-length')}

										</Form.Control.Feedback>
									}
									{repeatPassword && repeatPasswordLengthOk && !samePasswordOk &&
										<Form.Control.Feedback type="invalid">

											{t('error.repeat-password')}

										</Form.Control.Feedback>
									}
									{!repeatPassword  &&
										<Form.Control.Feedback type="invalid">

											{t('error.password-required')}

										</Form.Control.Feedback>
									}
								</Form.Group>
								<div className="mt-3">

									<Button className="button-primary w-100 text-transform-none" type="submit">
										{!props.activation && `${t('confirm')}`}
										{props.activation && `${t('confirm-and-activate')}`}
									</Button>

								</div>



							</Form>
						</div>
						<div className="d-flex align-items-center mt-5 justify-content-center footer-container">
							<Footer white={true} hideGala={true}></Footer>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ResetPassword;
