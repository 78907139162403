import { useHistory } from 'react-router-dom';
import { AuthContext } from '../globalStates';
import useUsersApi from '../hooks/useUsersApi';

import { useTranslation } from 'react-i18next';
import useLoader from '../components/loader/useLoader';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import CustomModal from '../components/modals/customModal';
import Footer from '../components/footer/footer';

export function ForgotPassword() {
	const [authState, setAuthState] = useContext(AuthContext);
	const [loader, showLoader, hideLoader] = useLoader();
	const { t } = useTranslation();
	const [, , , logoutUser, , , , , getSendResetPasswordEmail] = useUsersApi();
	const [email, setEmail] = useState<any>(undefined);
	const [validated, setValidated] = useState(false);
	const dispatch = useDispatch();
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const handleSubmit = async (e: any) => {
		const form = e.currentTarget;

		if (!validateAll(form)) {
			e.preventDefault();
			e.stopPropagation();
		} else {
			e.preventDefault();
			e.stopPropagation();

			showLoader();
			const response: any = await getSendResetPasswordEmail(email);

			hideLoader();
			if (response) {
				setShowSuccessModal(true);
			} else {
				setShowErrorModal(true);
			}
		}
		setValidated(true);
	};
	const handleOnEmailChange = (event: any) => {
		setEmail(event.target.value);
	};
	const handleOnLogout = async () => {
		const result: any = await dispatch(logoutUser());
		if (result) {
			await setAuthState(null);
		}
	};
	const handleCloseSuccessModal = async (e: any) => {
		if (e) {
			await handleOnLogout();
			history.push('/login');
		}
		setShowSuccessModal(false);
	};
	const handleCloseErrorModal = (e: any) => {
		setShowErrorModal(false);
	};
	const history = useHistory();
	const goToLanding = () => {
		history.push('/');
	}
	const validateAll = (form: any) => {
		return (
			form.checkValidity()
		);
	};

	return (
		<>
			{loader}
			{showSuccessModal && (
				<CustomModal
					isShowing={showSuccessModal}
					parentCallback={handleCloseSuccessModal}
					message={t('user.reset-password-message')}
					title={t('user.reset-password-title', {
						email: email
					})}
					buttonOK={t('Continue')}
					type="SUCCESS"
				/>
			)}
			{showErrorModal && (
				<CustomModal
					isShowing={showErrorModal}
					parentCallback={handleCloseErrorModal}
					message={t('error.email-not-registered')}
					title={t('error.modal-title-oops')}
					buttonOK={t('accept')}
					type="ERROR"
				/>
			)}

			<div className="row w-100 login-container">
				<div className="col-12 col-lg-6 image-container d-none" >
					<div className="login-image"></div>
				</div>
				<div className="col-12 p-0 m-0 d-flex justify-content-center">
					<div className="d-flex flex-column justify-content-center align-items-center">
						<div className="d-flex row mt-3 flex-column logo-container mb-4 justify-content-center align-items-center">
							<div className="logo white cursor-pointer" onClick={goToLanding}></div>
							<div className='inscription-secondary-title'>{t('user.forgot-password')}</div>
						</div>
						<div className='login-box'>
							<Form
								noValidate
								validated={validated}

								onSubmit={handleSubmit}
							>
								<Form.Group className="form-content pl-0" controlId="formE-mail">
									<Form.Label className="input-label mt-0">{`${t(
										'user.registered-email'
									)}`}</Form.Label>
									<Form.Control
										type="email"
										name="email"
										placeholder={t('user.email')}
										onChange={handleOnEmailChange}
										required
									/>
									{email && <Form.Control.Feedback type="invalid">
										{t('error.email-format')}

									</Form.Control.Feedback>}
									{!email && <Form.Control.Feedback type="invalid">

										{t('error.email-required')}
									</Form.Control.Feedback>}
								</Form.Group>

								<div className="mt-5 w-100 d-flex justify-content-center">

									<Button className="button-primary w-100" type="submit">
										{`${t('confirm')}`}
									</Button>

								</div>
							</Form>
						</div>
						<div className="d-flex align-items-center mt-5 justify-content-center footer-container">
							<Footer white={true} hideGala={true}></Footer>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default ForgotPassword;
