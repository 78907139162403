import { useEffect } from 'react';
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';

function AttendancePie(props) {
  const {
    data,
    outerRadius,
    innerRadius,
  } = props;

  const { t } = useTranslation();

  const margin = {
    top: 50, right: 50, bottom: 50, left: 50,
  };

  let chartData = [
    {
      notAttended: t('event.not-attended'),
      qty: 0
    },
    {
      attended: t('event.attended'),
      qty: 0
    }
  ];

  for (let user of data) {
      if (user.attend && user.inscriptionStatus === 'ATTENDEED'){
        chartData[1].qty = chartData[1].qty + 1
      }else if(user.attend && user.inscriptionStatus !== 'DENIED' && user.inscriptionStatus !== 'REJECTED'){
        chartData[0].qty = chartData[0].qty + 1
      }
  }
 
  const width = 2 * outerRadius + margin.left + margin.right;
  const height = 2 * outerRadius + margin.top + margin.bottom;

  const colorScale = d3     
    .scaleSequential()      
    .interpolator(d3.interpolateCool)      
    .domain([0, chartData.length]);

  useEffect(() => {
    drawChart();
  }, [chartData]);

  function drawChart() {
    // Remove the old svg
    d3.select('#pie-container')
      .select('svg')
      .remove();

    // Create new svg
    const svg = d3
      .select('#pie-container')
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    const arcGenerator = d3
      .arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius);

    const pieGenerator = d3
      .pie()
      .padAngle(0)
      .value((d) => d.qty);

    const arc = svg
      .selectAll()
      .data(pieGenerator(chartData))
      .enter();

    // Append arcs
    arc
      .append('path')
      .attr('d', arcGenerator)
      .attr('class', 'attendance-pie-bg')
      .style('fill', (_, i) => colorScale(i))
      .style('stroke', '#ffffff')
      .style('stroke-width', 0);

    // Append text labels
    // arc
    //   .append('text')
    //   .attr('text-anchor', 'middle')
    //   .attr('alignment-baseline', 'middle')
    //   .attr('class', 'attendance-pie-text')
    //   .text((d) => d.data[Object.keys(d.data)[0]] + ': '+ d.data.qty)
    //   .style('fill', (_, i) => colorScale(chartData.length - i))
    //   .attr('transform', (d) => {
    //     const [x, y] = arcGenerator.centroid(d);
    //     return `translate(${x}, ${y})`;
    //   });
  }    

  return <>
    <div className='d-flex flex-column mt-2 w-100'>
      <div className='d-flex flex-row align-items-center'>
        <div className='attend color-box mr-2'></div>
        <p className='m-0'>
          {chartData[1][Object.keys(chartData[1])[0]] + ': '+ chartData[1].qty}
        </p>
      </div>
      <div className='d-flex flex-row align-items-center'>
        <div className='no-attend color-box mr-2'></div>
        <p className='m-0'>
          {chartData[0][Object.keys(chartData[0])[0]] + ': '+ chartData[0].qty}
        </p>
      </div>
    </div>
    <div id="pie-container" />
  </> 
}

export default AttendancePie;